<template>
  <DrawerModal>
    <section style="height: calc(100dvh - 32px)">
      <div class="flex flex-col h-full">
        <div
          class="flex flex-col pb-4 px-[15px] md:px-10"
          :style="
            scrollPosition > 8
              ? 'box-shadow: 0px 2px 6px 0px rgba(161, 161, 161, 0.20);'
              : ''
          "
        >
          <h2 class="my-0 text-2xl font-normal text-black font-replica-pro">
            {{ $t("common.podcasts_and_reviews") }}
          </h2>
          <div class="flex flex-row gap-3.5 mt-3.5">
            <div class="flex flex-col w-full md:w-[288px]">
              <label
                for="height"
                class="block text-[13px] font-normal leading-6 text-custom-gray-10 font-replica-pro"
                v-text="$t('common.filter_by_model')"
              />
              <LazySelectorsProduct
                :options="store.products"
                name="model"
                value-field="id"
                v-model="filters.model"
                unique-key="id"
                :title="$t('common.select_here')"
                :placeholder="$t('common.all')"
                label="name"
                clear-icon-class="text-sm text-[#747474]"
                input-text-class="text-sm text-black"
                placeholder-class="text-sm text-black"
                container-class="bg-[#F1F1F1] text-black focus:border-black  border-transparent"
                parent-container="w-full "
              >
                <template #option="{ option }">
                  <div class="flex items-center">
                    <span class="text-sm">{{ option.name }}</span>
                  </div>
                </template>
              </LazySelectorsProduct>
            </div>
            <div class="flex flex-col w-full md:flex-1">
              <label
                for="height"
                class="block text-[13px] font-normal leading-6 text-custom-gray-10 font-replica-pro"
                v-text="$t('common.filter_by_date')"
              />
              <LazySelectorsDateRange
                v-model="filters.date"
                clear-icon-class="text-sm text-[#747474]"
                :placeholder="$t('common.all_time')"
                parent-container="w-full"
                input-text-class="text-sm text-black"
                placeholder-class="text-sm text-black"
                container-class="bg-[#F1F1F1] text-black focus:border-black  border-transparent"
              >
                <template #option="{ option }">
                  <div class="flex items-center">
                    <span class="text-sm">{{ option.name }}</span>
                  </div>
                </template>
              </LazySelectorsDateRange>
            </div>
          </div>
        </div>

        <XyzTransitionGroup
          id="podcast-row"
          class="flex flex-1 flex-col gap-6 pt-3.5 md:px-[15px] md:pl-10 md:pr-8 md:mr-2 overflow-y-auto pb-5 scrollabr-thumb"
          xyz="fade duration-5 appear-front-1 small-3 appear-small-0 stagger-0"
          appear-visible
          @scroll="onScroll"
        >
          <template v-if="podcastStore.isInitialLoading">
            <div
              class="w-full h-[265px] block md:hidden"
              v-if="appStore.isMobile"
              v-for="index in new Array(3)"
              :key="index"
            >
              <ContentLoader class="w-full h-[265px]" viewBox="0 0 100% 265">
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="213" />
                <rect x="16" y="220" rx="3" ry="3" width="90%" height="20" />
                <rect x="16" y="248" rx="3" ry="3" width="100" height="14" />
                <rect x="126" y="248" rx="3" ry="3" width="70" height="14" />
                <rect x="206" y="248" rx="3" ry="3" width="70" height="14" />
              </ContentLoader>
            </div>
            <div
              v-else
              class="w-full h-[116px] md:block hidden"
              v-for="index2 in new Array(7)"
              :key="index2"
            >
              <ContentLoader class="w-full h-[116px]" viewBox="5 0 490 116">
                <rect x="0" y="0" rx="3" ry="3" width="204" height="100%" />
                <rect x="221" y="0" rx="3" ry="3" width="100%" height="20" />
                <rect x="221" y="25" rx="3" ry="3" width="50%" height="20" />
                <rect x="221" y="60" rx="3" ry="3" width="30%" height="15" />
                <rect x="221" y="85" rx="3" ry="3" width="40%" height="15" />
              </ContentLoader>
            </div>
          </template>

          <div
            v-else-if="podcastStore.videos.length"
            v-for="(video, key) in podcastStore.videos"
            :key="key"
            :class="video.youtube_details ? 'block' : 'hidden'"
            class="flex-1 w-full md:flex-none"
          >
            <LazyPodcastsVideo
              :video="video"
              @play="(p) => (playItem = p)"
              v-if="video && video.youtube_details"
            />
          </div>
          <div class="flex items-center justify-center w-full h-full" v-else>
            <LazyNoResultsFound />
          </div>
          <template
            v-if="podcastStore.isLoading || podcastStore.isInitialLoading"
          >
            <div class="w-full h-[116px] relative">
              <ContentLoader
                v-if="appStore.isMobile"
                class="w-full h-[265px]"
                viewBox="0 0 100% 265"
              >
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="213" />
                <rect x="16" y="220" rx="3" ry="3" width="90%" height="20" />
                <rect x="16" y="248" rx="3" ry="3" width="100" height="14" />
                <rect x="126" y="248" rx="3" ry="3" width="70" height="14" />
                <rect x="206" y="248" rx="3" ry="3" width="70" height="14" />
              </ContentLoader>

              <ContentLoader
                v-else
                class="w-full h-[116px]"
                viewBox="5 0 490 116"
              >
                <rect x="0" y="0" rx="3" ry="3" width="204" height="100%" />
                <rect x="221" y="0" rx="3" ry="3" width="100%" height="20" />
                <rect x="221" y="25" rx="3" ry="3" width="50%" height="20" />
                <rect x="221" y="60" rx="3" ry="3" width="30%" height="15" />
                <rect x="221" y="85" rx="3" ry="3" width="40%" height="15" />
              </ContentLoader>

              <LazyTubeSpinner
                v-if="podcastStore.isLoading || podcastStore.isInitialLoading"
                class="text-[#E5E8E9] w-10 h-10 absolute top-0 left-[45%]"
              />
            </div>
          </template>
        </XyzTransitionGroup>
      </div>
      <PodcastsVideoPlay
        class="transition-all duration-75"
        :video="playItem"
        v-if="playItem"
        @close="playItem = null"
      />
    </section>
  </DrawerModal>
</template>

<script setup>
import { useVfm } from "vue-final-modal";
import { useMainStore } from "~/store";
import { usePodcastStore } from "~/store/podcast";
import { ContentLoader } from "vue-content-loader";
import { useAppStore } from "~/store/app";

const store = useMainStore();

const appStore = useAppStore();
const nuxtApp = useNuxtApp();
const podcastStore = usePodcastStore();
const vfm = useVfm();

const filters = reactive({
  model: "",
  date: {
    fromdate: "",
    todate: "",
  },
});
const playItem = ref(null);

const filterParams = ref({});
const scrollPosition = ref(0);
const defaultDate = ref({
  start: "",
  end: "",
});

const videos = ref(podcastStore.videos);

onBeforeMount(() => {
  if (nuxtApp.$initXyzAnim) {
    nuxtApp.$initXyzAnim();
  }

  podcastStore.fetchPodacasts(filterParams.value, true);
});

watch(
  filters,
  (newValue, oldValue) => {
    const podcastable = {
      type: "Product",
      id: filters.model,
    };

    const date_range = {
      from: filters.date.fromdate,
      to: filters.date.todate,
    };

    filterParams.value = { podcastable, date_range };
    if (!podcastStore.isLoading && !podcastStore.isInitialLoading) {
      podcastStore.fetchPodacasts(filterParams.value, true);
    }
  },
  { deep: true }
);

function onScroll(e) {
  scrollPosition.value = e.target.scrollTop;

  const el = e.target; // Get the scrolling element
  if (
    el.scrollHeight - el.scrollTop <= el.clientHeight + 10 &&
    podcastStore.hasLoadMore
  ) {
    // Check if bottom reached
    if (
      !podcastStore.isLoading &&
      !podcastStore.isInitialLoading &&
      podcastStore.hasLoadMore
    ) {
      podcastStore.fetchPodacasts(filterParams.value, false);
    }
  }
}

function onBack() {
  vfm.closeAll();
}
</script>
<style>
</style>
